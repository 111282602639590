/*
=========================================================
Muse - Vue Ant Design Dashboard - v1.0.0
=========================================================

Product Page: https://www.creative-tim.com/product/vue-ant-design-dashboard
Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by Creative Tim

=========================================================
The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. 
*/

import Vue from 'vue'
import Antd from 'ant-design-vue';
import Element, {MessageBox} from 'element-ui'
import { Loading} from "element-ui";
import 'ant-design-vue/dist/antd.css';
import App from './App.vue'
import DefaultLayout from './layouts/Default.vue'
import DashboardLayout from './layouts/Dashboard.vue'
import DashboardRTLLayout from './layouts/DashboardRTL.vue'
import router from './router'
import './permission' // permission control
// import './plugins/click-away'

import './scss/app.scss';
import store from "@/store";

Vue.prototype.$loading = Loading.service;
Vue.prototype.$elementconfirm = MessageBox.confirm;

Vue.use(Element, {
  size: 'medium' // set element-ui default size
})


Vue.use(Antd)
Vue.prototype.$antdconfirm = Vue.prototype.$confirm;

Vue.config.productionTip = false

// Adding template layouts to the vue components.
Vue.component("layout-default", DefaultLayout);
Vue.component("layout-dashboard", DashboardLayout);
Vue.component("layout-dashboard-rtl", DashboardRTLLayout);

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
}).$mount('#app')