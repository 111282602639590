import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)



let constantRoutes = [
	{
		path: '/login',
		name: '登录',
		meta: {
			layoutClass: 'layout-sign-up',
		},
		component: () => import('../views/Login.vue'),
	},
	{
		path: '/',
		redirect: 'login'
	},
	{
		// will match everything
		path: '/404',
		layout: "dashboard",
		component: () => import('../views/404.vue'),
	},
	{
		path: '/loans',
		name: '我的贷款',
		layout: "dashboard",
		component: () => import('../views/Loans.vue'),
	},
	{
		path: '/repay',
		name: '还款台账',
		layout: "dashboard",
		component: () => import('../views/Repay.vue'),
	},
	{
		path: '/profile',
		name: '个人资料',
		layout: "dashboard",
		meta: {
			layoutClass: 'layout-profile',
		},
		component: () => import('../views/Profile.vue'),
	},
	{
		path: '/register',
		name: '注册',
		meta: {
			layoutClass: 'layout-sign-up',
		},
		component: () => import('../views/Register.vue'),
	},
	{
		path: '*',
		redirect: '/404'
	}
]

// Adding layout property from each route to the meta
// object so it can be accessed later.
function addLayoutToRoute( route, parentLayout = "default" )
{
	route.meta = route.meta || {} ;
	route.meta.layout = route.layout || parentLayout ;
	
	if( route.children )
	{
		route.children = route.children.map( ( childRoute ) => addLayoutToRoute( childRoute, route.meta.layout ) ) ;
	}
	return route ;
}

constantRoutes = constantRoutes.map( ( route ) => addLayoutToRoute( route ) ) ;

const router = new VueRouter({
	mode: 'history', // 去掉url中的#
	base: process.env.BASE_URL,
	scrollBehavior: () => ({ y: 0 }),
	routes: constantRoutes
})

export default router
